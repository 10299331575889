import React from 'react';
import './KNSLogo.css';
import Thinkimg from '../../Assets/1.webp';
import { Link } from 'react-router-dom';

const KNSLogo = () => {
    return (
        <div className='upermain'>
             <div className='position-absolute top-0 start-50 translate-middle-x postposition '>
                <Link to='/Home'><img className="margin-imglife" src={Thinkimg} alt='thinkimg'/></Link>
             </div>
        </div>
    );
};

export default KNSLogo;