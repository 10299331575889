import React from 'react';
import Offcanvas from '../Offcanvas/Offcanvas';
import KNSLogo from '../KNSLogo/KNSLogo';
import LowerFooter from '../Footerlower/LowerFooter';
import { Helmet } from 'react-helmet';
import thanks from '../../Assets/thanks.webp';

const Thankyou = () => {
    return (
        <div>
            <Helmet>
            {`<script>!function(px){function e(t,e,c){var n="",r="";try{""!=(n=function(t,e){try{var c={};e.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(t,e,n){c[e]=n});return c.hasOwnProperty(t)?c[t]:""}catch(t){return""}}(t,c))?function(t,e,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=t+"="+e+r+";Path=/"}catch(t){}}(e,n,1):n=function(t){try{var e=document.cookie.match(new RegExp("(^| )"+t+"=([^;]+)"));if(e)return e[2]}catch(t){}return null}(e),r=""!=n&&null!=n?"&"+t+"="+n:"&"+t+"="}catch(t){}return r}var c="",n="",r="";try{n=e("ad","acf",c=window.location.href),r=e("col_ci","col_ci",c)}catch(t){console.log(t)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+px+"&_w=1&_t=2"+n+r+"&rd="+(new Date).getTime();(new Image).src=a}('16341');</script><noscript><img height='1' width='1' style='display:none' src='https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=16341&_w=1&_t=2'/></noscript>`}
            </Helmet>

            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>

            <div className='text-center'>
                <img src={thanks} className='w-100' alt='thankyou'/>
            </div>

            <LowerFooter></LowerFooter>
        </div>
    );
};

export default Thankyou;