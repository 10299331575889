import React, { useEffect } from 'react';
import Offcanvas from '../../../../../Offcanvas/Offcanvas';
import Faqcalisto from './Faq/Faqcalisto';

import Calistohome from './Home/Calistohome';
import Locationcalisto from './Location/Locationcalisto';
import Swippercalisto from './Swipper/Swippercalisto';
import KNSLogo from '../../../../../KNSLogo/KNSLogo';
import LowerFooter from '../../../../../Footerlower/LowerFooter';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

const Calistomain = () => {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname)
    },[]);


    return (
        <div>
            <Helmet>
                <title>Land and villa plots for Sale in Mysore| KNS Infrastructure</title>
                <meta name='description' content='Buy plot for sale in Mysore | KNS Calisto- offers MUDA Approved gated community plots and Residential sites in Mysore'/>
                <link rel="canonical" href="https://knsgroup.in/calisto"/>
            </Helmet>

            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>
            <Calistohome></Calistohome>
            <Locationcalisto></Locationcalisto>
            <Swippercalisto></Swippercalisto>
            <Faqcalisto></Faqcalisto>
            <LowerFooter></LowerFooter>
            
        </div>
    );
};

export default Calistomain;