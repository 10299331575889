import React from 'react';
import { Link } from 'react-router-dom';
import bangimg from '../../../Assets/bengaluru.webp';
import mysimg from '../../../Assets/mysuru.webp';
import './ProjectsWeb.css';
import Offcanvas from '../../Offcanvas/Offcanvas';
import LowerFooter from '../../Footerlower/LowerFooter';
import KNSLogo from '../../KNSLogo/KNSLogo';
import { Helmet } from 'react-helmet';

const ProjectsWeb = () => {
    return (
        <div>
            <Helmet>
                <title>Plot for Sale in Bangalore Featured This Week | KNS Infrastructure</title>
                <meta name='description' content='Plots for sale in Bangalore by KNS Infrastructure offers BDA, BIAPPA, and BMRDA approved premium villa plots'/>
                <link rel="canonical" href="https://knsgroup.in/projects" />
            </Helmet>
            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>
        <div className='container'>
            <div class="row g-4 text-center mt-5 ">
                <h1>PROJECTS</h1>
                <div class="col-lg-6 col-sm-12">
                    <div className='row d-flex justify-content-center image-container'>
                     <img className="w-100 " src={bangimg} alt='bangaluruimage' /> 
                    <Link class=" button-simple btn btn-none border border-1 " to='/allprojectsbengaluru'>
                    
                   Know More </Link>             


                  </div>
                </div>



                <div class="col-lg-6 col-sm-12 mb-4">
                    <div className='row d-flex justify-content-center  image-container'>
                    <img className="w-100" src={mysimg} alt='mysuruimage' />
                    <Link class=" button-simple btn btn-none border border-1 " to='/allprojectsmysuru'>
                    
                   Know More </Link> 
                  </div>
                </div>
            </div>
        </div>
        <LowerFooter></LowerFooter>
        </div>
    );
};

export default ProjectsWeb;