import React, { useState } from 'react';
import './Aboutuspage.css';
import Footerlower from '../../../Footerlower/LowerFooter';
import Offcanvas from '../../../Offcanvas/Offcanvas';
import topimg from '../../../../AssetsforAabout/1.jpg';
// import middleimg from '../../../../AssetsforAabout/2.png';
// import dot1 from '../../../../AssetsforAabout/3.jpg';
// import dot2 from '../../../../AssetsforAabout/4.jpg';
// import dot3 from '../../../../AssetsforAabout/5.jpg';
import KNSLogo from '../../../KNSLogo/KNSLogo';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { Helmet } from 'react-helmet';

const Aboutuspage = () => {

    const [counterOn, setCounterOn] = useState(false);

    return (
    
        <div >
             <Helmet>
                <title>KNS Infrastructure is most trusted plotted developer in Bangalore</title>
                <meta name='description' content='Do you want buy plots in Bangalore here the Most Trusted Plotted Developer in Bengaluru We Offering Premium Villa Plots and Residential sites'/>
                <link rel="canonical" href="https://knsgroup.in/about" />
            </Helmet>
            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>
         <div className=''>
            <div className='row pe-2'>
                <img className='w-100 mb-5' src={topimg} alt='about details'/>
            </div>

    <ScrollTrigger onEnter={()=> setCounterOn(true)
    } onExit={()=> setCounterOn(false)}>
            <div className='container text-center my-5'>

            
            <div className='row '>
            {/* <img className='w-100 mt-5' src={middleimg} alt='about details'/> */}

            {/* count animation */}

            <div className='col-lg-4 col-md-12 col-sm-12'>
                <h1 className=' h1-confi'>{counterOn && <CountUp start={0} end={35} duration={5} delay={0}/> }+ </h1>
                <p>PROJECTS</p>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12'>
                 <h1 className=' h1-confi'>{counterOn && <CountUp start={0} end={10000} duration={5} delay={0}/> }+</h1>
                 <p>HAPPY PLOT OWNER</p>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12'>
                  <h1 className=' h1-confi'>{counterOn &&<CountUp start={10000000} end={25000000} duration={5} delay={0}/> }+ </h1>
                 <p>SQUARE FEET DEVELOPED</p>
             </div>

            {/* count animation end here */}

            </div>
         </div>
         </ScrollTrigger>
         </div>
         

         {/* <div className='container text-center my-5'>
            <h2 className='margin-life mt-5 '>Our Core Values</h2>

                <div className='row '>
                    <div className='col-lg-4 col-sm-12 mt-2 text-center ' data-aos="fade-down"
                                                        data-aos-easing="linear"
                                                        data-aos-duration="1500">
                        <img className='width-size' src={dot1} alt=" logo details" />
                        
                    </div>
                    <div className='col-lg-4 col-sm-12 mt-2 text-center ' data-aos="fade-down"
                                                        data-aos-easing="linear"
                                                        data-aos-duration="1500">

                        <img className='width-size' src={dot2} alt=" logo details" />
                        
                    </div>
                    <div className='col-lg-4 col-sm-12 mt-2 text-center ' data-aos="fade-down"
                                                        data-aos-easing="linear"
                                                        data-aos-duration="1500">
                        <img className='width-size' src={dot3} alt="logo details" />
                        
                    </div>
                </div>

            </div>  */}
            {/* sadanand gadwal  */}
            <Footerlower></Footerlower>       

        </div>
    );
};

export default Aboutuspage;