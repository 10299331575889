import React, { useState } from 'react';
import './Knslocation.css';
import mapimg from '../../../Assets/Map.jpg';
import emailjs from "emailjs-com";

import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import Amora from '../Projects/Bangaloreprojects/AllprojectsBangaluru/Amora/Amora';



const Knslocation = () => {


    const navigate = useNavigate();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [showIframe, setShowIframe] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        projectname: '',
        name: '',
        email: '',
        number: '',

    })





    const sendEmail = (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        if (formData.projectname && formData.name && formData.email && formData.number) {
            emailjs.sendForm('service_v73c39b', 'template_1ohhfb9', e.target, 'Q_tnUooVuTOT2eItg')
                // emailjs.sendForm('service_z652qps', 'template_zw98f2n', e.target, 'BrVdjJ_dP2sCEqW45') ==its for website@knsgroup
                .then((result) => {
                    // alert('Thank You! We reach you soon!');
                    // Swal.fire(
                    //     'Thank You!',
                    //     'We will reach you soon',
                    //     'success'
                    //   );

                    // navigate('https://thankyoupage.knsgroup.in/');
                    window.location.href = 'https://thankyoupage.knsgroup.in/';

                    setFormData({
                        projectname: '',
                        name: '',
                        email: '',
                        number: '',
                    });

                }, (error) => {
                    // alert('Server Issus! Not Submitted');
                    Swal.fire(
                        'Oops!',
                        'Somethings Wrong, Please Try Again',
                        'error'
                    );
                    setFormData({
                        projectname: '',
                        name: '',
                        email: '',
                        number: '',
                    });
                });

        }
        else {
            Swal.fire(
                'Oops!',
                'Please Fill the details',
                'info'
            );
        }
        e.target.reset();

    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    return (
        <div>
            <div class="my-5 container">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 d-flex justify-content-center p-3">
                        {showIframe ? (
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.180304333893!2d77.5334144!3d12.9603116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3946ad80a39d%3A0x637aa8ba5c01f8ad!2sKNS%20Infrastructure%20Private%20Limited!5e0!3m2!1sen!2sin!4v1720854874094!5m2!1sen!2sin"
                                className="w-100"
                                width="600"
                                height="510"
                                style={{ border: '0' }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        ) : (
                            <a href="https://goo.gl/maps/gC3pyqGQrC2ZNgLK8" target="_blank" rel="noopener noreferrer">
                                <img className="w-100" src={mapimg} alt="KNS map" />
                            </a>
                        )}
                    </div>



                    <div class="col-lg-6 col-sm-12  p-2">
                        <h2 className=' mt-1fw-bold padding-setup'> Let's Talk </h2>

                        <br />

                        <form class="row" onSubmit={sendEmail}>
                            <div class="col-md-12 mt-5">

                                <select id="inputState" name='project_name' class="form-select" value={formData.projectname} onChange={(event) => setFormData({ ...formData, projectname: event.target.value })}>
                                    <option value='' className='d-none'>Select Project</option>
                                    <option value='KNS BILLORE'>KNS BILLORE</option>
                                    <option value='KNS AMORA'>KNS AMORA</option>
                                    <option value='KNS LAUREL'>KNS LAUREL</option>
                                    <option value='KNS ATHENA II'>KNS ATHENA PHASE II</option>
                                    <option value='KNS AADRIKA'>KNS AADRIKA</option>
                                    <option value='KNS CALISTO'>KNS CALISTO</option>
                                    <option value='KNS FRESCO'>KNS FRESCO</option>
                                    <option value='KNS AROHA'>KNS AROHA</option>
                                </select>
                            </div>
                            <div class="col-12">
                                {/* <label for="inputEmail4" class="form-label">First Name</label> */}
                                <input type="text" class="form-control form-design " placeholder="Name" aria-label="First name" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })} />
                            </div>


                            <div class="col-12">
                                {/* <label for="inputAddress" class="form-label">Contact Number</label> */}
                                <input type="number" class="form-control form-design hide-number-arrow" id="inputAddress" placeholder="Contact Number" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })} />
                            </div>
                            <div class="col-12">
                                {/* <label for="inputAddress" class="form-label">Email Id</label> */}
                                <input type="email" class="form-control form-design" id="inputAddress" placeholder="Email Id" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })} />
                            </div>
                            <div class="form-check my-5 ms-2">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Accept all <Link className='text-dec' to='/privacy'>Terms</Link> & <Link className='text-dec' to='/privacy'>Conditions</Link>
                                </label>
                            </div>

                            <div class="col-12 ">
                                <button type="submit" class="btn button-color mb-5 " disabled={!isChecked || isButtonDisabled} className={isChecked ? 'enabled' : 'disabled'} >Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Knslocation;