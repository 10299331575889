import React from 'react';
import './common.css';
import Offcanvas from '../Offcanvas/Offcanvas';
import LowerFooter from '../Footerlower/LowerFooter';
import KNSLogo from '../KNSLogo/KNSLogo';

const Dis = () => {
    return (
        <div>
            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>
            <div className='container p-5 my-5'>
                <h1 className='mb-5'>Disclaimer</h1>
            <p className='text-alg'>
            The artistic representations, 360 degree views, elevations, walkthroughs, and e-brochures displayed on this website are for representation purposes only and do not form part of any agreement or legal binding on the part of KNS. Any expressed views are not a part of the actual deliverables and soft furnishings, furniture, and gadgets are not included in the offering. The product and technology displayed are also for representation purposes only and are not guaranteed to be used. Specifications are indicative and may change as decided by the company or the competent authority. Marginal variation may be necessary during construction, and the extent, number, and variety of equipment/appliances and their brand are tentative and liable to change at the sole discretion of the company. Any objections raised in this regard will not be entertained.<br/><br/>

            It is your responsibility to evaluate the accuracy, completeness, and usefulness of any opinions, advice, services, or other information provided on this website. The authors, publishers, and distributors of this information do not assume any liability whatsoever in connection with its use and are not rendering legal or professional advice or opinions on specific facts or matters. For specific legal or tax advice, consult your own legal or tax advisor based on your personal situation.<br/><br/>

            KNS and its related, affiliated, and subsidiary companies are not liable for any direct, indirect, special, incidental, or consequential damages arising out of the use of the information provided on this website.<br/><br/>

            </p>
            </div>
            <LowerFooter></LowerFooter>
        </div>
    );
};

export default Dis;