import React, { useState } from 'react';
import './Allprojectsbangaluru.css';
import projectback from '../../../../AssetsforProjects/projectback.webp';
import backsmall from '../../../../AssetsforProjects/backsmall.webp';
import newlaunch from '../../../../AssetsforProjects/newlaunch.gif';
import ComingSoon from '../../../../AssetsforProjects/comingSoon.gif';
import PreBooking from '../../../../AssetsforProjects/pre booking v1 (1).gif'

// import knslogo from '../../../../AssetsforProjects/6.png';
import billore from '../../../../AssetsforIndividualProject/Billoreimages/billore1.webp';
import billoredetails from '../../../../AssetsforIndividualProject/Billoreimages/billore.webp';
import billoremob from '../../../../AssetsforIndividualProject/Billoreimages/billorescrollmob.webp';
import laurel from '../../../../AssetsforIndividualProject/Laurel/laurel1.webp';
import laureldetails from '../../../../AssetsforIndividualProject/Laurel/laurel2.webp';
import laurelmob from '../../../../AssetsforIndividualProject/Laurel/laurel3.webp';
import amora from '../../../../AssetsforIndividualProject/Amoraimages/amora1.webp';
import amoradetails from '../../../../AssetsforIndividualProject/Amoraimages/amora2.webp';
import amoramob from '../../../../AssetsforIndividualProject/Amoraimages/amora3.webp';
import athenatwo from '../../../../AssetsforProjects/athenatwo.jpg';
import athenatwodetails from '../../../../AssetsforProjects/Athenatwodetails.jpg';
import athenatwomob from '../../../../AssetsforProjects/athenatwomob.jpg';
import aadrika from '../../../../AssetsforProjects/aadrikamain.webp';
import aadrikadetails from '../../../../AssetsforProjects/aadrikadetails.webp';
import aadrikamob from '../../../../AssetsforProjects/aadrikamob.webp';
import alora from '../../../../AssetsforProjects/alora1.webp';
import aloradetails from '../../../../AssetsforProjects/alora3.webp';
import aloramob from '../../../../AssetsforProjects/alora2.webp';
import aroha from '../../../../AssetsforProjects/aroha.jpg';
import arohadetails from '../../../../AssetsforProjects/arohadetails.jpg';
import arohamob from '../../../../AssetsforProjects/arohamob.jpg';
import atharva from '../../../../AssetsforProjects/atharva.jpg';
import atharvadetails from '../../../../AssetsforProjects/atharvadetails.jpg';
import atharvamob from '../../../../AssetsforProjects/atharvamob.jpg';
import oasis from '../../../../AssetsforProjects/oasis.jpg';
import oasisdetails from '../../../../AssetsforProjects/oasisdetails.jpg';
import oasismob from '../../../../AssetsforProjects/oasismob.jpg';
import anirvan from '../../../../AssetsforProjects/anirvan.jpg';
import anirvandetails from '../../../../AssetsforProjects/anirvandetails.jpg';
import anirvanmob from '../../../../AssetsforProjects/anirvanmob.jpg';
import fresco from '../../../../AssetsforProjects/fresco.jpg';
import frescodetails from '../../../../AssetsforProjects/frescodetails.jpg';
import frescomob from '../../../../AssetsforProjects/frescomob.jpg';
import athena from '../../../../AssetsforProjects/athena.jpg';
import athenadetails from '../../../../AssetsforProjects/athenadetails.jpg';
import athenamob from '../../../../AssetsforProjects/athenamob.jpg';
import ojas from '../../../../AssetsforProjects/ojas.jpg';
import ojasdetails from '../../../../AssetsforProjects/ojasdetails.jpg';
import ojasmob from '../../../../AssetsforProjects/ojasmob.jpg';
import nester from '../../../../AssetsforProjects/nester.jpg';
import nesterdetails from '../../../../AssetsforProjects/nesterdetails.jpg';
import nestermob from '../../../../AssetsforProjects/nestermob.jpg';
import jivanza from '../../../../AssetsforProjects/anagha.jpg';
import jivanzadetails from '../../../../AssetsforProjects/jivanzadetails.jpg';
import jivanzamob from '../../../../AssetsforProjects/jivanzamob.jpg';
import kalpa from '../../../../AssetsforProjects/kalpa.jpg';
import kalpadetails from '../../../../AssetsforProjects/kalpadetails.jpg';
import kalpamob from '../../../../AssetsforProjects/kalpamob.jpg';
import neoliva from '../../../../AssetsforProjects/neoliva.jpg';
import neolivadetails from '../../../../AssetsforProjects/neolivadetails.jpg';
import neolivamob from '../../../../AssetsforProjects/neolivamob.jpg';
import unnati from '../../../../AssetsforProjects/unnati.jpg';
import unnatidetails from '../../../../AssetsforProjects/unnatidetails.jpg';
import unnatimob from '../../../../AssetsforProjects/unnatimob.jpg';
// import anagha from '../../../../AssetsforProjects/anagha.jpg';
// import anaghadetails from '../../../../AssetsforProjects/anaghadetails.jpg';
// import anaghamob from '../../../../AssetsforProjects/anaghamob.jpg';
import archesa from '../../../../AssetsforProjects/archesa.jpg';
import archesadetails from '../../../../AssetsforProjects/archesadetails.jpg';
import archesamob from '../../../../AssetsforProjects/archesamob.jpg';
import laurelbottom from '../../../../AssetsforIndividualProject/Laurel/laurel logo.webp';
import amorabottom from '../../../../AssetsforIndividualProject/Amoraimages/fulllogoamora.webp';
import billorebottom from '../../../../AssetsforIndividualProject/Billoreimages/billorebottom.webp';
import Athenatwobottom from '../../../../AssetsforProjects/logo athena01.webp';
import aadrikabottom from '../../../../AssetsforProjects/aadrikabottom.jpeg';
import atharvabottom from '../../../../AssetsforProjects/atharvabottom.jpg';
import frescobottom from '../../../../AssetsforProjects/frescobottom.jpg';
import arohabottom from '../../../../AssetsforProjects/arohabottom.jpg';
import nesterbottom from '../../../../AssetsforProjects/nesterbottom.jpg';
import anirvanbottom from '../../../../AssetsforProjects/anirvanbottom.jpg';
import athenabottom from '../../../../AssetsforProjects/athenabottom.jpg';
import kalpabottom from '../../../../AssetsforProjects/kalpabottom.jpg';
import neolivabottom from '../../../../AssetsforProjects/neolivabottom.jpg';
import oasisbottom from '../../../../AssetsforProjects/oasisbottom.jpg';
import ojasbottom from '../../../../AssetsforProjects/ojasbottom.jpg';
import unnatibottom from '../../../../AssetsforProjects/unnatibottom.jpg';
import archesabottom from '../../../../AssetsforProjects/archisabottom.jpg';
import jivanzabottom from '../../../../AssetsforProjects/anaghabottom.jpg';
import alorabottom from '../../../../AssetsforProjects/alorabottom.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import Footerlower from '../../../Footerlower/LowerFooter';
import Offcanvas from '../../../Offcanvas/Offcanvas';
import KNSLogo from '../../../KNSLogo/KNSLogo';
import { Helmet } from 'react-helmet';
const Allprojectben = () => {
    const [billoreHovered, setbilloreHovered] = useState(false);
    const [amoraHovered, setamoraHovered] = useState(false);
    const [laurelHovered, setlaurelHovered] = useState(false);
    const [athenatwoHovered, setathenatwoHovered] = useState(false);
    const [aadrikaHovered, setaadrikaHovered] = useState(false);
    const [aloraHovered, setaloraHovered] = useState(false);
    const [arohaHovered, setarohaHovered] = useState(false);
    const [atharvaHovered, setatharvaHovered] = useState(false);
    const [oasisHovered, setoasisHovered] = useState(false);
    const [anirvanHovered, setanirvanHovered] = useState(false);
    const [frescoHovered, setfrescoHovered] = useState(false);
    const [athenaHovered, setathenaHovered] = useState(false);
    const [ojasHovered, setojasHovered] = useState(false);
    const [nesterHovered, setnesterHovered] = useState(false);
    const [kalpaHovered, setkalpaHovered] = useState(false);
    const [neolivaHovered, setneolivaHovered] = useState(false);
    const [jivanzaHovered, setjivanzaHovered] = useState(false);
    const [unnatiHovered, setunnatiHovered] = useState(false);
    // const [anaghaHovered, setanaghaHovered] = useState(false);
    const [archesaHovered, setarchesaHovered] = useState(false);
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div className='' >
            <Helmet>
                <title>Plots for sale in Bangalore | KNS Infrastructure</title>
                <meta name='description' content='Buy premium villa plot for sale in Bangalore | KNS infrastructure- Bangalore’s most trusted plotted developers. Offers BDA and gated community plots for sale' />
                <link rel="canonical" href="https://knsgroup.in/allprojectsbengaluru" />
            </Helmet>
            <Offcanvas></Offcanvas>
            <KNSLogo></KNSLogo>
            <div>
                <img src={projectback} className='w-100 h-100 img-view' alt='background project page' />
                <img src={backsmall} className='w-100 h-100 img-viewmob' alt='background project page' />
            </div>
            {/* slider is here */}
            <div className='container my-5'>
                <div className='row '>
                    <Swiper
                        modules={[Navigation, Autoplay, Pagination]}
                        navigation
                        autoplay={{ delay: 3000 }} // Adjust autoplay delay as needed
                        // pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                spaceBetween: 10,
                                slidesPerView: 1,
                            },
                            480: {
                                spaceBetween: 10,
                                slidesPerView: 2,
                            },
                            768: {
                                spaceBetween: 15,
                                slidesPerView: 2,
                            },
                            1024: {
                                spaceBetween: 30,
                                slidesPerView: 4,
                            },
                        }}
                        className='image-slider text-center'
                    >

                        {/* billore  */}
                        <SwiperSlide>
                            <div className=' mb-2'>
                                <img
                                    src={billoreHovered ? billoredetails : billore}
                                    onMouseEnter={() => setbilloreHovered(true)}
                                    onMouseLeave={() => setbilloreHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="athenaimage "
                                />
                                <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." />
                                <div className=''>
                                    <img src={billoremob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={billorebottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='https://knsbillore.com'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* amora */}
                        <SwiperSlide>
                            <div className=' mb-2'>
                                <img
                                    src={amoraHovered ? amoradetails : amora}
                                    onMouseEnter={() => setamoraHovered(true)}
                                    onMouseLeave={() => setamoraHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="athenaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={amoramob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={amorabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/amora'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=' mb-2'>
                                <img
                                    src={laurelHovered ? laureldetails : laurel}
                                    onMouseEnter={() => setlaurelHovered(true)}
                                    onMouseLeave={() => setlaurelHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="athenaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={laurelmob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={laurelbottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/laurel'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=' mb-2'>
                                <img
                                    src={athenatwoHovered ? athenatwodetails : athenatwo}
                                    onMouseEnter={() => setathenatwoHovered(true)}
                                    onMouseLeave={() => setathenatwoHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="athenaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={athenatwomob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={Athenatwobottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/athena2'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className=' mb-2'>
                                <img
                                    src={aadrikaHovered ? aadrikadetails : aadrika}
                                    onMouseEnter={() => setaadrikaHovered(true)}
                                    onMouseLeave={() => setaadrikaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="Arohaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={aadrikamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={aadrikabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/aadrika'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className=' mb-2'>
                                <img
                                    src={aloraHovered ? aloradetails : alora}
                                    onMouseEnter={() => setaloraHovered(true)}
                                    onMouseLeave={() => setaloraHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="Arohaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={aloramob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={alorabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/alora'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className=''>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className=' mb-2'>
                                <img
                                    src={arohaHovered ? arohadetails : aroha}
                                    onMouseEnter={() => setarohaHovered(true)}
                                    onMouseLeave={() => setarohaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="Arohaimage "
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={arohamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={arohabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Aroha</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/aroha'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={atharvaHovered ? atharvadetails : atharva}
                                    onMouseEnter={() => setatharvaHovered(true)}
                                    onMouseLeave={() => setatharvaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="arharvaimage"
                                />
                                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                                <div className=''>
                                    <img src={atharvamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={atharvabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'> Atharva</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/atharva'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={frescoHovered ? frescodetails : fresco}
                                    onMouseEnter={() => setfrescoHovered(true)}
                                    onMouseLeave={() => setfrescoHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="frescoimage"
                                />
                                <div className=''>
                                    <img src={frescomob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={frescobottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                {/* <h1 className='fw-light font-size mb-4'>Fresco</h1> */}
                                <div onClick={scrollToTop}>
                                    <Link className='button-color ' to='/fresco'>Know More</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={nesterHovered ? nesterdetails : nester}
                                    onMouseEnter={() => setnesterHovered(true)}
                                    onMouseLeave={() => setnesterHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="nesterimage"
                                />
                                <div className=''>
                                    <img src={nestermob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={nesterbottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={oasisHovered ? oasisdetails : oasis}
                                    onMouseEnter={() => setoasisHovered(true)}
                                    onMouseLeave={() => setoasisHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="oasisimage"
                                />
                                <div className=''>
                                    <img src={oasismob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={oasisbottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={athenaHovered ? athenadetails : athena}
                                    onMouseEnter={() => setathenaHovered(true)}
                                    onMouseLeave={() => setathenaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="athenaimage"
                                />
                                <div className=''>
                                    <img src={athenamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={athenabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={anirvanHovered ? anirvandetails : anirvan}
                                    onMouseEnter={() => setanirvanHovered(true)}
                                    onMouseLeave={() => setanirvanHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="anirvanimage"
                                />
                                <div className=''>
                                    <img src={anirvanmob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={anirvanbottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={ojasHovered ? ojasdetails : ojas}
                                    onMouseEnter={() => setojasHovered(true)}
                                    onMouseLeave={() => setojasHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="ojasimage"
                                />
                                <div className=''>
                                    <img src={ojasmob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={ojasbottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={neolivaHovered ? neolivadetails : neoliva}
                                    onMouseEnter={() => setneolivaHovered(true)}
                                    onMouseLeave={() => setneolivaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="neolivaimage"
                                />
                                <div className=''>
                                    <img src={neolivamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={neolivabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={kalpaHovered ? kalpadetails : kalpa}
                                    onMouseEnter={() => setkalpaHovered(true)}
                                    onMouseLeave={() => setkalpaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="kalpaimage"
                                />
                                <div className=''>
                                    <img src={kalpamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={kalpabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={jivanzaHovered ? jivanzadetails : jivanza}
                                    onMouseEnter={() => setjivanzaHovered(true)}
                                    onMouseLeave={() => setjivanzaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="jivanzaimage"
                                />
                                <div className=''>
                                    <img src={jivanzamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={jivanzabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={unnatiHovered ? unnatidetails : unnati}
                                    onMouseEnter={() => setunnatiHovered(true)}
                                    onMouseLeave={() => setunnatiHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="unnatiimage"
                                />
                                <div className=''>
                                    <img src={unnatimob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={unnatibottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <img src={ } class=" image-size p-3  " id='imgchange' alt="..." />
                            <div className='text-center '>
                                <img
                                    src={anaghaHovered ? anaghadetails : anagha}
                                    onMouseEnter={() => setanaghaHovered(true)}
                                    onMouseLeave={() => setanaghaHovered(false)}
                                    class=" image-size displayimg px-2"
                                    alt="anaghaimage"
                                />
                                <img src={anaghamob} className='mobimg px-2' alt='mobileviewimage' />
                                <img src={newlaunch} class=" image-sizing px-2" alt="..." />
                                <img src={jivanzabottom} class=" w-100 p-2 mb-2" alt="..." />
                                <Link className='button-color ' to='/calisto'>Know More</Link>
                            </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                            <div className='text-center '>
                                <img
                                    src={archesaHovered ? archesadetails : archesa}
                                    onMouseEnter={() => setarchesaHovered(true)}
                                    onMouseLeave={() => setarchesaHovered(false)}
                                    class=" image-size displayimg px-2 pt-5"
                                    alt="archesaimage"
                                />
                                <div className=''>
                                    <img src={archesamob} className='mobimg w-100 px-2 pt-5' alt='mobileviewimage' />
                                    <img src={archesabottom} class=" w-100 px-2 mb-3" alt="..." />
                                </div>
                                <Link className='button-color ' to='/undermaintenance'>Know More</Link>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <Footerlower></Footerlower>
        </div>
    );
};
export default Allprojectben;


