//refernce Alora
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import "./Amora.css";
import Offcanvas from "../../../../../Offcanvas/Offcanvas";
import KNSLogo from "../../../../../KNSLogo/KNSLogo";
import Faqcalisto from "../../../Mysoreprojects/Allprojectmysore/Calisto/Faq/Faqcalisto";
import Footerlower from "../../../../../Footerlower/LowerFooter";
import aloradetails from "../../../../../../AssetsforIndividualProject/Amoraimages/amoralogo.webp";
import aloramain from "../../../../../../AssetsforIndividualProject/Amoraimages/amoramain.jpg";
import AmoraLocation from "./AmoraLocation/AmoraLocation";
import AmoraMasterPlan from "../../../../../../AssetsforIndividualProject/Amoraimages/Amora _master_plan.jpg";
import AmoraMasterPlan1 from "../../../../../../AssetsforIndividualProject/Amoraimages/1.png";
import AmoraMasterPlanBlur from "../../../../../../AssetsforIndividualProject/Amoraimages/2.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Amora = () => {
  // State for controlling the modal
  const [showtab, setshowtab] = useState(1);

  const navigate = useNavigate();
  // State for controlling the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const openModal = (imageSrc) => {
    console.log("Modal opening with image: ", imageSrc); // Debugging log
    setModalImageSrc(imageSrc); // Sets the image source for the modal
    setIsModalOpen(true); // This should open the modal
  };

  console.log("Is Modal Open: ", isModalOpen); // Log to check state changes

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc("");
  };

  const [activeFilter, setActiveFilter] = useState("");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    console.log(`${filter} Filter Clicked!`);
    // Implement filtering logic here if needed
  };

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (formData.name && formData.email && formData.number) {
      emailjs
        .sendForm(
          "service_v73c39b",
          "template_5e93gnf",
          e.target,
          "Q_tnUooVuTOT2eItg"
        )
        .then(
          (result) => {
            window.location.href = "https://thankyoupage.knsgroup.in/";

            setFormData({
              name: "",
              email: "",
              number: "",
            });
          },
          (error) => {
            // alert('Server Issus! Not Submitted');
            Swal.fire("Oops!", "Somethings Wrong, Please Try Again", "error");
            setFormData({
              name: "",
              email: "",
              number: "",
            });
          }
        );
      e.target.reset();
    } else {
      Swal.fire("Oops!", "Please Fill the details", "info");
    }
  };

  const handleclick = (e) => {
    setshowtab(e);
  };
  return (
    <div>
      <Helmet>
        <title>
          KNS Amora Land & Sites for sale in Bangalore |Off Mysore Road{" "}
        </title>
        <meta
          name="description"
          content="Land for sale in Bangalore | KNS Alora Offers BMICAPA approved gated community plots for sale in Bangalore"
        />
        <link rel="canonical" href="https://knsgroup.in/alora" />
      </Helmet>

      <Offcanvas></Offcanvas>
      <KNSLogo></KNSLogo>
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6 col-sm-12 order-last order-lg-first d-flex flex-column">
            <div className="d-flex flex-column justify-content-center ">
              <div className="text-center">
                <img
                  src={aloradetails}
                  className="w-25 top-mar"
                  alt="amoralogo"
                />
              </div>
              <div>
                <p className="fw-light ps-5 pe-5 py-2 mb-5 font-sizer">
                  "Amora" carries the enchanting meaning of love,
                  encapsulating the essence of affection and connection.
                  The intention is to create a vibrant and inclusive environment
                  that caters to the diverse needs of its residents. <br />
                  and why not? Alora is a Great word for light.
                  <br />
                  <br />
                  It’s not just about owning a piece of land;
                  it’s about embracing a lifestyle that embodies elegance,
                  convenience, and opulence.
                  <br />
                  <br />
                  The emphasis on aesthetics, functionality, and environmental
                  sustainability makes KNS Amora a promising example of Upscale
                  plotted development.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 order-first order-lg-last d-flex align-items-center">
            <img
              className="w-100 margin-imager"
              src={aloramain}
              alt="atharvaimagedetails"
            />
          </div>
        </div>
      </div>

      <div className="container p-3">
        <form class="row g-3" onSubmit={sendEmail}>
          <div class="col-md-3 col-sm-12">
            <label for="inputEmail4" class="form-label">
              Project Name
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              name="project_name"
              value="KNS AMORA"
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputEmail4" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              name="name"
              value={formData.name}
              onChange={(event) =>
                setFormData({ ...formData, name: event.target.value })
              }
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputPassword4" class="form-label">
              Contact Number
            </label>
            <input
              type="number"
              class="form-control hide-number-arrow"
              id="inputPassword4"
              name="number"
              value={formData.number}
              onChange={(event) =>
                setFormData({ ...formData, number: event.target.value })
              }
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputPassword4" class="form-label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="inputPassword4"
              name="user_email"
              value={formData.email}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
          </div>
          <div className="col-6">
            <button
              type="submit"
              class="btn button-color my-5"
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* <div className="col-lg-12 col-md-12 col-sm-12 text-center my-5">
          <div className="row">
            <div className="col-12 ">
              <div
                class="btn-group flexdirect "
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className={
                    showtab === 1
                      ? "button-active button-width me-4 mt-1"
                      : "button-color button-width me-4 mt-1 "
                  }
                  onClick={() => handleclick(1)}
                >
                  Master Plan
                </button>
                <button
                  type="button"
                  className={
                    showtab === 2
                      ? "button-active button-width me-4 mt-1"
                      : "button-color button-width me-4 mt-1 "
                  }
                  onClick={() => handleclick(2)}
                >
                  Floor Plan
                </button>

              </div>
            </div>
            <div>
              <div className={showtab === 1 ? "display-block" : "display-none"}>
                <img
                  src={AmoraMasterPlan}
                  className="w-75 top-mar mb-2"
                  alt="Factsheet"
                />
              </div>
              <div className={showtab === 2 ? "display-block" : "display-none"}>
                <img
                  src={AmoraMasterPlan}
                  className="w-75 top-mar mb-2"
                  alt="Factsheet"
                />
              </div>
            
            </div>
          </div>
        </div> */}
      <section className="plans-section">
        <div className="row text-center">
          <h3>Plans</h3>
          <div className="filter-buttons mb-3">
            <button
              className={`filter-btn ${
                activeFilter === "master" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("master")}
            >
              Master Plan
            </button>
            {/* <button
              className={`filter-btn ${
                activeFilter === "floor" ? "active" : ""
              }`}
              onClick={() => handleFilterClick("floor")}
            >
              Floor Plan
            </button> */}
          </div>
        </div>
        <div className="container">
  <div className="row justify-content-center">
    {/* First Plan Item */}
    <div className="col-6 col-md-4 plan-item text-center custom-gap"> {/* Apply custom class */}
      <div
        onClick={() => {
          console.log("Clicked!"); // For debugging
          openModal(AmoraMasterPlan);
        }}
      >
   <img
    src={AmoraMasterPlan1}
    alt="Amora Master Plan"
    className="w-75 top-mar mb-2 image-hover-effect" // Add hover effect class
/>

        <p>Amora Master Plan</p>
      </div>
    </div>

    {/* Second Plan Item */}
    {/* <div className="col-6 col-md-4 plan-item text-center custom-gap"> 
      <div
        onClick={() => {
          console.log("Clicked!"); // For debugging
          openModal(AmoraMasterPlanBlur);
        }}
      >
        <img
          src={AmoraMasterPlanBlur}
          alt="Amora Master Plan 2"
          className="w-75 top-mar mb-2" 
        />
        <p>Floor Plan</p>
      </div>
    </div> */}
  </div>
</div>


        {/* Modal */}
        {isModalOpen && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img
              className="modal-content"
              src={modalImageSrc}
              alt="Modal View"
            />
          </div>
        )}
      </section>
      <AmoraLocation></AmoraLocation>
      {/* <AmoraSlider></AmoraSlider> */}
      <Faqcalisto></Faqcalisto>
      <Footerlower></Footerlower>
    </div>
  );
};

export default Amora;
