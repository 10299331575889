import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "./Home.css";
import Offcanvas from "../../Offcanvas/Offcanvas";
import KNSLogo from "../../KNSLogo/KNSLogo";
import backone from "../../../Assets/back 1.jpg";
import backtwo from "../../../Assets/back 2.jpg";
import backthree from "../../../Assets/back 3.jpg";
import backonemob from "../../../Assets/back 1 mob.jpg";
import backtwomob from "../../../Assets/back 2 mob.jpg";
import backthreemob from "../../../Assets/back 3 mob.jpg";
import backaadrika from "../../../AssetsforProjects/aarika web back.webp";
import backaadrikamob from "../../../AssetsforProjects/aarika web mob.webp";
import backathena2 from "../../../AssetsforProjects/athenaback.webp";
import backathena2mob from "../../../AssetsforProjects/athenabackmob.webp";
import backlaurel from "../../../AssetsforIndividualProject/Laurel/laurelwebback.webp";
import backmoblaurel from "../../../AssetsforIndividualProject/Laurel/laurelmobback.webp";
import backananta from "../../../AssetsforIndividualProject/Laurel/webbackananta.webp";
import backmobananta from "../../../AssetsforIndividualProject/Laurel/webbackanantamob.webp";
import backamora from "../../../AssetsforIndividualProject/Amoraimages/amoradesktop.webp";
import backmoamora from "../../../AssetsforIndividualProject/Amoraimages/amoramobile.webp";
import backbillore from "../../../AssetsforIndividualProject/Billoreimages/billoredesktop.webp";
import backmobillore from "../../../AssetsforIndividualProject/Billoreimages/billoremobile.webp";
import newlaunch from "../../../AssetsforProjects/newlaunch.gif";

const Home = () => {
  return (
    <div>
      <Offcanvas />
      <KNSLogo />
      <div className="text-center carousel">
        <div className="row">
          <div className="col-lg-12">
            <Swiper
              modules={[Navigation, Autoplay, Pagination]}
              navigation
              autoplay={{ delay: 3000 }} // Adjust autoplay delay as needed
              // pagination={{ clickable: true }}
              breakpoints={{
                0: {
                  spaceBetween: 10,
                  slidesPerView: 1,
                },
                480: {
                  spaceBetween: 10,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 15,
                  slidesPerView: 1,
                },
                1024: {
                  spaceBetween: 30,
                  slidesPerView: 1,
                },
              }}
              className="image-slider text-center"
            >
              <SwiperSlide>
                <Link to="https://knsbillore.com/">
                {/* <img src={newlaunch} class=" image-sizing px-2 pt-5 " alt="..." /> */}
                  <img
                    src={backbillore}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="https://knsbillore.com/">
                  <img
                    src={backmobillore}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/amora">
                  <img
                    src={backamora}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/amora">
                  <img
                    src={backmoamora}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/laurel">
                  <img
                    src={backlaurel}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/laurel">
                  <img
                    src={backmoblaurel}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="https://knsananta.com/">
                  <img
                    src={backananta}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="https://knsananta.com/">
                  <img
                    src={backmobananta}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/athena2">
                  <img
                    src={backathena2}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/athena2">
                  <img
                    src={backathena2mob}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/aadrika">
                  <img
                    src={backaadrika}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/aadrika">
                  <img
                    src={backaadrikamob}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/calisto">
                  <img
                    src={backone}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/calisto">
                  <img
                    src={backonemob}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/aroha">
                  <img
                    src={backtwo}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/aroha">
                  <img
                    src={backtwomob}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/fresco">
                  <img
                    src={backthree}
                    className="w-100 desk"
                    alt="main background"
                  />
                </Link>
                <Link to="/fresco">
                  <img
                    src={backthreemob}
                    className="w-100 mob"
                    alt="main background"
                  />
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
